import React, { useState } from "react";
import Stars from "../../components/global/Logout/Stars";



function Faq() {

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
    const moverReview = [
        {
            name: "Laurel Ivy",
            date: "5 months ago",
            // tittle: "Movers on the way is the best!",
            description: "I highly recommend this business.  We bought a new house and had a leak day one!! Alanazo came out the same day and pointed out other areas of concern.  He gave me a  great price,  fixed the poor workmanship of the previous contractor and was professional and got the job done quickly.  We will definitely recommend them to everyone and use them again when it is time to replace it roof.",
            rate: 5,
            red: "Google",
            enlace: 'https://maps.app.goo.gl/HXHq82A2ixomYpA2A',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/material%20Dash%2F2000px-Google_G_Logo.svg_.png?alt=media&token=a0b834d8-25ae-45a1-bdd2-ecd81fbddf2b',
        },

        {
            name: "Farrukh Gujjar",
            date: "5 months ago",
            // tittle: "Movers on the way is the best!",
            description: "We used this roofing company for a recent repair and were extremely happy with the results. They were responsive, professional, and provided a detailed estimate upfront. The work was completed quickly and efficiently, and the team was friendly and knowledgeable. Highly recommend for all your roofing needs.",
            rate: 5,
            red: "Google",
            enlace: 'https://maps.app.goo.gl/HXHq82A2ixomYpA2A',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/material%20Dash%2F2000px-Google_G_Logo.svg_.png?alt=media&token=a0b834d8-25ae-45a1-bdd2-ecd81fbddf2b',
        },
        {
            name: "عثمان الهادي",
            date: "5 months ago",
            // tittle: "Movers on the way is the best!",
            description: "They were professional, courteous, and respectful throughout the entire process. Their attention to detail and commitment to customer satisfaction was outstanding. The quality of their work was excellent, and the price was very fair. I highly recommend A&A Exteriors and their roofing services to anyone looking for quality work at a fair price. They are an excellent choice for anyone looking for reliable and respectful roofing services.",
            rate: 5,
            red: "Google",
            enlace: 'https://maps.app.goo.gl/HXHq82A2ixomYpA2A',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/material%20Dash%2F2000px-Google_G_Logo.svg_.png?alt=media&token=a0b834d8-25ae-45a1-bdd2-ecd81fbddf2b',
        },

        {
            name: "Timothy Thomas",
            date: "7 months ago",
            // tittle: "Movers on the way is the best!",
            description: "A&A Exteriors is a top-notch home improvement service contractor that specializes in expert, quality and superior roofing services in Milwaukee, WI. I recently had them replace the roof on my home and was extremely impressed with the results. They were professional, knowledgeable and provided excellent customer service throughout the entire process. They were also very efficient and completed the job in a timely manner.",
            rate: 5,
            red: "Google",
            enlace: 'https://maps.app.goo.gl/HXHq82A2ixomYpA2A',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/material%20Dash%2F2000px-Google_G_Logo.svg_.png?alt=media&token=a0b834d8-25ae-45a1-bdd2-ecd81fbddf2b',
        },

        {
            name: "Oscar McGowan",
            date: "2 months ago",
            // tittle: "Movers on the way is the best!",
            description: "Los he usado antes para hacer mi techo, hicieron un trabajo rápido y de calidad. Así que los usé de nuevo para hacer el revestimiento de mi garaje... los mismos resultados. Satisfecho.",
            rate: 5,
            red: "Google",
            enlace: 'https://maps.app.goo.gl/HXHq82A2ixomYpA2A',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/material%20Dash%2F2000px-Google_G_Logo.svg_.png?alt=media&token=a0b834d8-25ae-45a1-bdd2-ecd81fbddf2b',
        },




    ];


    return (
        <section className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1">
            {
                moverReview.map((item, index) => {
                    return (
                        <article key={index} className="h-auto p-2 flex flex-col rounded-3xl bg-gray-100 w-auto">
                            <div className="flex flex-col">
                                <div className="p-2 flex space-x-2">
                                    <div className="flex justify-center">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                            alt="perfil-foto"
                                            className="w-[50px] h-auto rounded-full object-cover" />
                                    </div>
                                    <div>
                                        <span className="text-[20px] font-semibold"> {item.name} </span>
                                        <Stars score={item.rate} />
                                    </div>
                                    <div className="flex items-end text-[13px] text-gray-400">
                                        <span>{item.date}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 ">
                                <ReadMore >{item.description}</ReadMore>
                            </div>
                            <div className="w-full flex items-center space-x-3 ml-3">
                                <div className="w-[25px] h-[25px] flex items-center">
                                    <img src={item.ima} alt="agni" className="w-full mx-auto" />
                                </div>
                                <div className="flex flex-col items-center justify-start">
                                    <p>Posted On</p>
                                    <a className="text-blue-500 w-full" href={item.enlace}>{item.red}</a>
                                </div>
                            </div>
                        </article>
                    );
                })
            }
        </section>
    );
}

export default Faq;