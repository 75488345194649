import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";

function Recent() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Recent">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Recent Project"
          Subheader="Recent Project"
          bgimg={`${rpdata?.gallery?.[60]}`}
        />

        <div id="rwl-output"></div>
        <div id="rwl-map"></div>
        
      </div>
    </BaseLayout>
  );
}

export default Recent;
